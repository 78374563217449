import React from "react";
import AboutGameList from "./About-GameList";
import AboutPromotion from "./AboutPromotion";

const About = () => {
  return (
    <section className="section-about pb-20 z-3 relative">
      <div class="section-background-overlay"></div>
      <div className="md:container mx-auto ">
        <div className="grid grid-cols-2 text-black">
          <div className="flex justify-end justify-center text-black ">
            <div className="text-center h-auto flex my-auto flex-col items-center">
              <h2 className="title-hero">ABOUT JILIKO </h2>
              <p>
                <span>
                  The game in JILIKO are one-of-a-kind, especially the slot
                  game, which has the rich gaming functions that are very
                  attractive to players. Most of the players are busy with their
                  daily lives, and they rarely have time and opportunity to
                  relax in a real casinos far abroad.
                </span>
                <br />
              </p>
            </div>
          </div>
          <div className="text-black">
            <div className="about-right">
              <img
                src="/assets/images/woman-with-long-black-hair-red-dress-is-standing-front-red-background_clipdrop-background-removal-1.webp"
                alt="woman with long black hair red dress is standing front red background"
                srcset=""
                width="600px"
                height="600px"
                className="z-40"
              />
            </div>
          </div>{" "}
        </div>
      </div>
      <AboutGameList  />
      <AboutPromotion />
    </section>
  );
};

export default About;
