import React from "react";

const AboutGameList = () => {
  return (
    <section className="my-20 relative  ">
      <div className="md:container mx-auto">
        <div className="grid grid-cols-2 ">
          <div className="flex justify-end w-auto">
            <div>
              <div className="top-games p-3">
                <div className="heading-title flex justify-center mb-3">
                  <h2 className="top-games-title">JILIKO TOP GAMES</h2>
                </div>
                <div className="grid grid-cols-2 gap-3   hover-games">
                  <div className="top">
                    <img
                      src="/assets/images/topgames-1.webp"
                      alt="top games"
                      srcset=""
                      width="300px"
                      height="auto"
                      className="rounded-lg images"
                    />
                  </div>
                  <div className="top">
                    {" "}
                    <img
                      src="/assets/images/topgames-2.png"
                      alt="top games"
                      srcset=""
                      width="300px"
                      height="auto"
                      className="rounded-lg images"
                    />
                  </div>
                  <div className="top">
                    {" "}
                    <img
                      src="/assets/images/hot-games1.png"
                      alt="top games"
                      srcset=""
                      width="300px"
                      height="auto"
                      className="rounded-lg images"
                    />
                  </div>
                  <div className="top">
                    {" "}
                    <img
                      src="/assets/images/hot-games.png"
                      alt="top games"
                      srcset=""
                      width="300px"
                      height="auto"
                      className="rounded-lg images"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {" "}
            <div className="features-games p-3 m-0">
              <div className="heading-title flex justify-center mb-3">
                <h2 className="top-games-title">JILIKO FEATURES GAMES</h2>
              </div>
              <div className="grid grid-cols-2 gap-3  ">
                <div className="top">
                  <img
                    src="/assets/images/featured-games1.png"
                    alt="top games"
                    srcset=""
                    width="auto"
                    height="auto"
                    className="rounded-lg images"
                  />
                </div>
                <div className="top">
                  {" "}
                  <img
                    src="/assets/images/Jili-games-Super-Bingo.png"
                    alt="top games"
                    srcset=""
                    width="auto"
                    height="auto"
                    className="rounded-lg images"
                  />
                </div>
                <div className="top">
                  {" "}
                  <img
                    src="/assets/images/features3.jpg"
                    alt="top games"
                    srcset=""
                    width="auto"
                    height="auto"
                    className="rounded-lg images"
                  />
                </div>
                <div className="top">
                  {" "}
                  <img
                    src="/assets/images/features4.jpg"
                    alt="top games"
                    srcset=""
                    width="auto"
                    height="auto"
                    className="rounded-lg images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutGameList;
