import React from "react";

const Footer = () => {
  return (
    <footer class="footer-container">
      <div className="footer-title md:container mx-auto  py-3">
        <div className="flex flex-col ">
          <h6 className="flex justify-center">Copyright © JILIKO 2023 . All rights reserved.</h6>
          <img
            src="/assets/images/footer-games.webp"
            alt="gamelist"
            width="auto"
            srcset=""
            className="img-footer py-4"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
