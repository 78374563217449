import React from "react";
import { Link, NavLink } from "react-router-dom";

// import { connect } from 'react-redux'
import { FaUser, FaDice } from "react-icons/fa";
export const Header = () => {
  const NavBar = [
    {
      label: "Jiliko",
      links: "https://bj88ph.live/af/Zd946zCP/jili-ko-ph",
    },
    {
      label: "Slots",
      links: "https://bj88ph.live/af/Zd946zCP/jili-ko-ph",
    },
    {
      label: "Fisher Game",
      links: "https://bj88ph.live/af/Zd946zCP/jili-ko-ph",
    },
    {
      label: "Live Game",
      links: "https://bj88ph.live/af/Zd946zCP/jili-ko-ph",
    },
    {
      label: "Promotion",
      links: "https://bj88ph.live/af/Zd946zCP/jili-ko-ph",
    },
    {
      label: "Article",
      links: "https://bj88ph.live/af/Zd946zCP/jili-ko-ph",
    },
  ];

  return (
    <div className="sticky z-30 top-0 bg-header">
      <header class="text-gray-600 body-font ">
        <div class="header-wrapper flex flex-wrap p-5 flex-col md:flex-row items-center">
          <a
            href="https://bj88ph.live/af/Zd946zCP/jili-ko-ph"
            class=" title-font font-medium items-center flex justify-center w-48"
          >
            <img
              src="https://i0.wp.com/jili-ko.ph/wp-content/uploads/2022/10/Picture.png?fit=210%2C75&ssl=1"
              alt="Logo"
              srcset=""
              width="110px"
              px
              height="75px"
            />
          </a>

          <nav className="nav-wrapper flex flex-wrap items-center justify-center grow ">
            {NavBar.map((item, index) => (
              <NavLink
                to={item.links}
                // class="div-links text-center grow active py-2"
                className={`  text-center grow  py-2 ${
                  item === NavBar ??
                  item === "https://bj88ph.live/af/Zd946zCP/jili-ko-ph"
                    ? "div-links active"
                    : ""
                } curser-pointer`}
                key={index}
              >
                <span className="uppercase text-center ">{item.label}</span>
              </NavLink>
            ))}
          </nav>
          <div className="grid grid-cols-2 w-auto ">
            <div
              className="grow flex justify-center
            "
            >
              <Link to="https://bj88ph.live/af/Zd946zCP/jili-ko-ph">
                <button class=" align-end items-center  border-0 py-1 px-3  rounded text-base mt-4 md:mt-0 bg-blue-500 text-white pulse-grow-on-hover flex pulse-register">
                  <FaUser className="me-2" /> Register
                </button>
              </Link>
            </div>
            <div className="relative mr-16">
              <Link to="https://bj88ph.live/af/Zd946zCP/jili-ko-ph">
                <button class=" border-2  py-1 px-3 rounded text-base mt-4 md:mt-0 pulse-more pulse-grow-on-hover ">
                  <span className="flex z-10 text-white">
                    <FaDice className="me-2" /> More Casino
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
