import React from "react";
import { FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
const Hero = () => {
  return (
    <div className="hero-container relative z-1">
      <div className="background-overlay"></div>

      <div class="container flex items-center h-screen mx-auto z-20 ">
        <div class="hero-wrapper flex flex-col justify-center text-center z-30">
          <div class="hero-text md:w-1/2 md:mx-auto mb-10 ">
            <h1 class="title text-3xl font-bold uppercase mb-10"> Jiliko</h1>
            <p class="subtitle text-center">
              JILIKO Online Casino, the most popular slot game brand in
              Philippines, is suitable for players of all ages. It is easy to
              play and be solved. The more often you play this game, the more
              addictive you are to it, but also the more chances for you to win
              the top prize.
            </p>
          </div>
          <div
            className="grow flex justify-center cursor-pointer
            "
          >
            <Link to="https://bj88ph.live/af/Zd946zCP/jili-ko-ph">
              <button class="hero-register-button cursor-pointer">
                <span className="flex items-center px-5 py-1 ">
                  <FaUser className="me-1" /> Register
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
