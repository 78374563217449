import React from "react";
import { MdChevronRight } from "react-icons/md";
const AboutPromotion = () => {
  return (
    <div className="py-18 ">
      <div className="sm:container mx-auto">
        <div className="title">
          <h3 className="text-4xl flex justify-center">JILIKO PROMOTION</h3>
        </div>
        <div className="promotion-img mt-5 ">
          <div className="grid grid-cols-3 gap-4 md:w-1/2 lg:w-2/3 mx-auto">
            <img
              src="/assets/images/PromotionInfoLanguage-11.jpg"
              alt="promotion"
              className=" "
            />
            <img
              src="/assets/images/PromotionInfoLanguage-13.jpg"
              alt="promotion"
              className=" "
            />
            <img
              src="/assets/images/PromotionInfoLanguage-14.jpg"
              alt="promotion"
              className=""
            />{" "}
            <div className="flex align-center col-span-3  items-center justify-end">
              <MdChevronRight size={20} />
              <a
                href="https://bj88ph.live/af/Zd946zCP/jili-ko-ph"
                className="underline"
              >
                {" "}
                More Promotions
              </a>
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default AboutPromotion;
