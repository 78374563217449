import React from "react";

import { Outlet } from "react-router-dom";
import Footer from "../footer/Footer";
import { Header } from "../header/Header";

const Layout = () => {
  return (
    <div>
      <Header />

      <main className="min-h-screen">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
